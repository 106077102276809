import React from "react";
import { Link } from "react-router-dom";
import {
  MapPin,
  Menu,
  X,
  ChevronRight,
  Star,
  Instagram,
  Linkedin,
  Users,
  Trophy,
  Search,
  Calendar,
} from "lucide-react";
import ".././landingPage.css";
import logo from "../images/logo.png";
import mapa from "../images/mapa.png";
import mapaVertical from "../images/mapaVertical.png";
import calendarioEventos from "../images/calendarioEventos.png";
import AOS from "aos";
import "aos/dist/aos.css";
import { useNavigate } from "react-router-dom"; // Importa el hook de navegación
import SignIn from "./sign-in";
import { useState, useEffect, useContext } from "react";
import { UserContext } from ".././contexts/userContext.js";
import SeparadorLanding from "./separadorLanding.js";

import aroLanding from "../images/aroLanding.JPG";
import fondoHome from "../images/homeFondo.JPG";

const LandingPage = () => {
  const [isMenuOpen, setIsMenuOpen] = React.useState(false);
  const navigate = useNavigate(); // Hook para navegar
  const { currentUser } = useContext(UserContext);

  const handleLogoClick = () => {
    navigate("/"); // Redirige a la página principal
    window.parent.location = window.parent.location.href;
  };

  React.useEffect(() => {
    AOS.init({
      duration: 1000, // Duración de la animación en ms
      easing: "ease-in-out", // Suavizado
      once: false, // Animar solo una vez
    });
  }, []);

  return (
    <div
      className="min-h-screen bg-zinc-900 text-white"
      style={{
        overflowX: "hidden",
        fontFamily: "Arial Rounded MT Bold, sans-serif",
      }}
    >
      {/* Navigation */}
      <nav className="fixed top-0 left-0 right-0 z-50 flex items-center justify-between px-4 lg:px-8 py-3 border-b border-zinc-800 bg-zinc-900/95 backdrop-blur-sm">
        <img
          src={logo}
          alt="logo"
          className="h-10 w-10"
          onClick={handleLogoClick}
        />
        <div className="flex items-center space-x-4">
          <h1 className="text-2xl font-bold md:hidden">QNPDP</h1>
        </div>
        <div
          className="hidden md:flex items-center justify-center space-x-6"
          style={{ fontWeight: 600 }}
        >
          <a
            href="#features"
            className="hover:text-orange-500 transition-colors text-zinc-300"
          >
            Funciones
          </a>
          <a
            href="#about"
            className="hover:text-orange-500 transition-colors text-zinc-300"
          >
            Quienes Somos
          </a>
          <a
            href="#mapa"
            className="hover:text-orange-500 transition-colors text-zinc-300"
          >
            Mapa
          </a>
          <a
            href="#contact"
            className="hover:text-orange-500 transition-colors text-zinc-300"
          >
            Contacto
          </a>
          <a
            href="/app"
            className="bg-orange-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-orange-600 transition-colors"
          >
            Ir a la App
          </a>
        </div>
        <button
          className="md:hidden"
          onClick={() => setIsMenuOpen(!isMenuOpen)}
        >
          {isMenuOpen ? <X /> : <Menu />}
        </button>
      </nav>

      {/* Mobile Menu */}
      {isMenuOpen && (
        <div
          className="md:hidden fixed inset-x-0 z-40 bg-zinc-900/95 backdrop-blur-sm p-4"
          style={{ marginTop: 60, fontWeight: 600 }}
        >
          <div className="flex flex-col space-y-4">
            <a
              href="#features"
              className="hover:text-orange-500 transition-colors text-zinc-300"
            >
              Funciones
            </a>
            <a
              href="#about"
              className="hover:text-orange-500 transition-colors text-zinc-300"
            >
              Quienes somos
            </a>
            <a
              href="#mapa"
              className="hover:text-orange-500 transition-colors text-zinc-300"
            >
              Mapa
            </a>
            <a
              href="#contact"
              className="hover:text-orange-500 transition-colors text-zinc-300"
            >
              Contacto
            </a>
            <a
              href="/app"
              className="bg-orange-500 text-white px-4 py-2 rounded-lg font-semibold hover:bg-orange-600 transition-colors"
            >
              Ir a la App
            </a>
          </div>
        </div>
      )}

      {/* Hero Section */}
      <section
        className="relative min-h-screen min-h-[calc(100vh-64px)] flex items-center justify-center bg-cover bg-center"
        style={{
          backgroundImage: `url(${fondoHome})`,
        }}
      >
        <div className="absolute inset-0 bg-black/80"></div>
        <div className="relative z-20 text-center px-4 py-20 max-w-6xl mx-auto">
          <h1 className="text-4xl sm:text-5xl md:text-7xl font-bold mb-6 bg-clip-text text-orange-500">
            Canchas de <span style={{ color: "white" }}>basquet</span> públicas
            en
            <span style={{ color: "white" }}> Argentina</span>.
          </h1>
          <p className="text-lg sm:text-xl md:text-2xl mb-8 text-zinc-300 max-w-2xl mx-auto">
            Descubrí mas de 1000 canchas, con información actualizada y mirá
            cuántos jugadores hay en tiempo real. Ademas conoce todos los
            eventos del mundo street.
          </p>
          <div className="flex flex-col sm:flex-row gap-4 justify-center items-center">
            {!currentUser ? <SignIn buttonFormat="landing"></SignIn> : ""}
            <a
              href="/app"
              className="bg-orange-500 text-white px-8 py-3 rounded-lg text-lg font-semibold hover:bg-orange-600 transition-all transform hover:scale-105 w-full sm:w-auto"
            >
              Probar la app
            </a>
          </div>
        </div>
      </section>

      {/* Features Section */}
      <section id="features" className="py-20 px-4 lg:px-8 bg-zinc-800">
        <div className="max-w-6xl mx-auto" data-aos="fade-up">
          <div className="text-center mb-12">
            <h2 className="text-3xl font-bold mb-4">
              Funciones para los players
            </h2>
            <p className="text-zinc-400 max-w-2xl mx-auto">
              Todo lo que necesitás para encontrar, jugar y compartir canchas
              públicas de basket en cualquier lugar de Argentina.
            </p>
          </div>
          <div className="grid sm:grid-cols-2 md:grid-cols-4 gap-8">
            <FeatureCard
              title="Encontrá Canchas"
              description="Explorá nuestro mapa y encontrá canchas públicas en cualquier ciudad o barrio. Ayudaté con los filtros inteligentes para encontrar la cancha perfecta."
              icon={<Search className="w-8 h-8 text-orange-500" />}
            />
            <FeatureCard
              title="Información actualizada"
              description="Revisá la data de las canchas, viendo cantidad de aros, horarios, gente activa, links a las comunidades y fotos actualizadas. Lee y comparti comentarios y noticias sobre las canchas, de las diferentes comunidades."
              icon={<MapPin className="w-8 h-8 text-orange-500" />}
            />
            <FeatureCard
              title="Judagores en vivo"
              description="Mirá cuántos jugadores están en la cancha antes de salir de casa, o arma partidos para cierto horario, juga con gente, cuando vos quieras."
              icon={<Users className="w-8 h-8 text-orange-500" />}
            />
            <FeatureCard
              title="Calendario de eventos"
              description="El primer calendario centralizado con toda la data de eventos del basquet street. Las comunidades y QNPDP trabajamos para mantenerte actualizad con todos los torneos, clases, shows y eventos del basquet street."
              icon={<Calendar className="w-8 h-8 text-orange-500" />}
            />
          </div>
        </div>
      </section>

      {/* Who We Are Section */}
      <section className="py-20 px-4 lg:px-8 bg-zinc-900" id="about">
        <div className="max-w-6xl mx-auto">
          <div className="grid md:grid-cols-2 gap-12 items-center">
            <div className="order-2 md:order-1" data-aos="fade-right">
              <h2 className="text-3xl font-bold mb-6">¿Quiénes Somos?</h2>
              <p className="text-zinc-400 mb-6">
                Somos una comunidad apasionada por el streetball. Nuestra misión
                es visibilizar canchas públicas y acercar el básquet a todos,
                desde Buenos Aires hasta el ultimo rincon de Argentina. A su
                vez, buscamos conectar players entre si, para que puedas ir a
                jugar con gente, cuando quieras.
              </p>
              <div className="grid grid-cols-2 gap-6 mb-8">
                <div className="bg-zinc-800 p-6 rounded-lg">
                  <div className="text-orange-500 font-bold text-3xl mb-2">
                    +1000
                  </div>
                  <div className="text-zinc-400">Canchas registradas</div>
                </div>
                <div className="bg-zinc-800 p-6 rounded-lg">
                  <div className="text-orange-500 font-bold text-3xl mb-2">
                    +600
                  </div>
                  <div className="text-zinc-400">Players en la comunidad</div>
                </div>
              </div>
              <div className="flex flex-col sm:flex-row gap-4">
                <div className="flex items-center gap-2">
                  <Users className="text-orange-500" />
                  <span>Impulsado por la comunidad</span>
                </div>
                <div className="flex items-center gap-2">
                  <Trophy className="text-orange-500" />
                  <span>Enfocado en los players</span>
                </div>
              </div>
            </div>
            <div className="order-1 md:order-2">
              <div className="relative">
                <div className="absolute -bottom-6 -right-6 bg-orange-500 p-6 rounded-lg">
                  <div className="text-3xl font-bold">5★</div>
                  <div className="text-sm">Rating Players</div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>

      {/* Map Preview Section */}
      <section className="py-20 px-4 lg:px-8 bg-zinc-900" id="mapa">
        <div className="max-w-6xl mx-auto" data-aos="fade-up-left">
          <div className="bg-zinc-800 rounded-lg p-6 md:p-8 flex flex-col md:flex-row items-center gap-8">
            <div className="flex-1">
              <h2 className="text-3xl font-bold mb-4">
                <span style={{ color: "#EC5306" }}> Explorá el </span>mapa
                interactivo
              </h2>
              <p className="text-zinc-400 mb-6">
                Nuestro mapa te permite encontrar canchas públicas de básquet en
                cualquier parte de Argentina. Accedé a información actualizada,
                fotos, links a la comunidad, cantidad de aros, comentarios de
                jugadores, y mas. Combínalo con nuestros filtros inteligentes y
                encontrá la cancha que necesites.
              </p>
              <a
                href="/app"
                className="flex items-center space-x-2 text-orange-500 hover:text-orange-400 transition-colors group"
              >
                <span>Probar el mapa</span>
                <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
            <div className="flex-1 w-full">
              <img
                src={mapaVertical}
                alt="Mapa"
                className="h-30 w-full rounded-lg md:hidden"
              />
              <img
                src={mapa}
                alt="Mapa"
                className="h-30 w-full rounded-lg hidden md:flex"
              />
            </div>
          </div>
        </div>
      </section>

      <SeparadorLanding imageUrl={aroLanding}></SeparadorLanding>

      <section className="py-20 px-4 lg:px-8 bg-zinc-900" id="mapa">
        <div className="max-w-6xl mx-auto" data-aos="fade-up-left">
          <div className="bg-zinc-800 rounded-lg p-6 md:p-8 flex flex-col md:flex-row items-center gap-8">
            <div className="flex-1">
              <h2 className="text-3xl font-bold mb-4">
                Mantenete actualizado, con el{" "}
                <span style={{ color: "#EC5306" }}>calendario del street</span>
              </h2>
              <p className="text-zinc-400 mb-6">
                Este calendario te permite enterarte de todos los eventos del
                mundo street basket en Argentina. Clases, torneos, shows, etc.
                Podes cargar eventos de tu comunidad y armar la difusion por
                aca.
              </p>
              <a
                href="/app?to=calendario-eventos"
                className="flex items-center space-x-2 text-orange-500 hover:text-orange-400 transition-colors group"
              >
                <span>Ver calendario</span>
                <ChevronRight className="w-4 h-4 transform group-hover:translate-x-1 transition-transform" />
              </a>
            </div>
            <div className="flex-1 w-full">
              <img
                src={calendarioEventos}
                alt="Calendario"
                className="h-30 w-full rounded-lg md:hidden"
              />
              <img
                src={calendarioEventos}
                alt="Mapa"
                className="h-30 w-full rounded-lg hidden md:flex"
              />
            </div>
          </div>
        </div>
      </section>

      {/* Testimonials Section */}
      <section className="py-20 px-4 lg:px-8 bg-zinc-800">
        <div className="max-w-6xl mx-auto text-center">
          <h2 className="text-3xl font-bold mb-12">Que dicen los jugadores</h2>
          <div className="flex justify-center mb-8">
            {[...Array(5)].map((_, i) => (
              <Star
                key={i}
                className="w-6 h-6 text-orange-500"
                fill="currentColor"
              />
            ))}
          </div>
          <blockquote className="text-xl text-zinc-300 mb-6 max-w-2xl mx-auto">
            "Muy buena idea, faltaba algo asi que junte el street y la
            tecnologia."
          </blockquote>
          <cite className="text-zinc-400">- Jugador</cite>
        </div>
      </section>

      {/* CTA Section */}
      <section className="bg-orange-500 relative">
        <div className="py-20 px-4 lg:px-8" data-aos="zoom-in-down">
          <div className="max-w-4xl mx-auto text-center">
            <h2 className="text-3xl font-bold mb-6">
              ¿Estas listo para unirte a esta comunidad de street basquet?
            </h2>
            <p className="text-xl mb-8">
              Suamte a los miles de players que ya entraron a la app y
              encontraron la cancha ideal. Unite como jugador activo para poder
              armar partidos cuando quieras.
            </p>
            <a
              href="/app"
              className="bg-white text-orange-500 px-8 py-3 rounded-tr-[10px] rounded-br-[10px] rounded-tl-[10px] rounded-bl-[10px] text-lg font-semibold hover:bg-zinc-100 transition-all transform hover:scale-105"
            >
              Empezar ahora
            </a>
          </div>
        </div>
      </section>

      {/* Footer */}
      <footer className="bg-zinc-900 text-zinc-400 py-12 px-4 lg:px-8">
        <div className="max-w-6xl mx-auto">
          <div className="flex flex-col md:flex-row justify-between items-center gap-8">
            <div className="text-center md:text-left">
              <h3 className="text-white font-bold mb-2">
                Que No Pare De Picar.
              </h3>
              <p className="text-sm">Encontra la cancha perfecta, siempre.</p>
            </div>

            <div className="flex flex-wrap justify-center gap-8">
              <div>
                <h4 className="text-white font-bold mb-2">Funciones</h4>
                <div className="flex gap-4 text-sm">
                  <a
                    href="#features"
                    className="hover:text-orange-500 transition-colors"
                  >
                    Funciones
                  </a>
                  <a
                    href="#mapa"
                    className="hover:text-orange-500 transition-colors"
                  >
                    Mapa
                  </a>
                </div>
              </div>

              <div>
                <h4 className="text-white font-bold mb-2">Empresa</h4>
                <div className="flex gap-4 text-sm">
                  <a
                    href="#about"
                    className="hover:text-orange-500 transition-colors"
                  >
                    Quienes Somos
                  </a>
                  <a
                    href="#"
                    className="hover:text-orange-500 transition-colors"
                  >
                    Contacto
                  </a>
                </div>
              </div>

              <div>
                <h4 className="text-white font-bold mb-2">Seguinos:</h4>
                <div className="flex justify-center gap-4">
                  <a
                    href="https://www.instagram.com/quenoparedepicar/"
                    className="hover:text-orange-500 transition-colors"
                    target="_blank"
                  >
                    <Instagram />
                  </a>
                  <a
                    href="https://www.linkedin.com/company/que-no-pare-de-picar/"
                    className="hover:text-orange-500 transition-colors"
                    target="_blank"
                  >
                    <Linkedin />
                  </a>
                </div>
              </div>
            </div>
          </div>

          <div className="border-t border-zinc-800 mt-8 pt-8 text-center text-sm">
            <p>&copy; 2024 Que No Pare De Picar. All rights reserved.</p>
          </div>
        </div>
      </footer>
    </div>
  );
};

const FeatureCard = ({ title, description, icon }) => (
  <div className="bg-zinc-900 p-6 rounded-lg transform hover:scale-105 transition-transform">
    <div className="mb-4">{icon}</div>
    <h3 className="text-xl font-semibold mb-2">{title}</h3>
    <p className="text-zinc-400 ">{description}</p>
  </div>
);

export default LandingPage;
