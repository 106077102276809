import React, { useEffect, useState } from "react";
import { useParams } from "react-router-dom";
import { getDatabase, ref, get } from "firebase/database";
import { Box, Typography, Modal } from "@mui/material";
import Navbar from "./navbar";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  Marker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Slider from "react-slick";

const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

const EventoDetalle = () => {
  const { eventId } = useParams(); // Obtener el ID del evento desde la URL
  const [evento, setEvento] = useState(null);
  const [openModal, setOpenModal] = useState(false); // Estado para el modal de imagen
  const [locations, setLocations] = useState([]);

  useEffect(() => {
    const fetchEvent = async () => {
      const db = getDatabase();
      const eventRef = ref(db, `events/${eventId}`);

      try {
        const snapshot = await get(eventRef);
        if (snapshot.exists()) {
          setEvento(snapshot.val()); // Guardar el evento en el estado
        } else {
          console.log("No se encontró el evento");
        }
      } catch (error) {
        console.error("Error al obtener el evento:", error);
      }
    };

    fetchEvent();
  }, [eventId]);

  useEffect(() => {
    if (evento && evento.location) {
      console.log("Ubicación recibida:", JSON.stringify(evento.location));
      setLocations([evento.location]); // Aseguramos que sea un array
    }
  }, [evento]);

  const PoiMarkers = ({ pois }) => {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isMouseOverInfoWindow, setIsMouseOverInfoWindow] = useState(false);
    const [closeTimeout, setCloseTimeout] = useState(null);

    const handleMouseOver = (poi) => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
      setSelectedPlace(poi);
    };

    const handleMouseOut = () => {
      if (!isMouseOverInfoWindow) {
        const timeout = setTimeout(() => {
          setSelectedPlace(null);
        }, 800);
        setCloseTimeout(timeout);
      }
    };

    const handleClick = (poi) => {
      setSelectedPlace(poi);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOver = () => {
      setIsMouseOverInfoWindow(true);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOut = () => {
      setIsMouseOverInfoWindow(false);
      const timeout = setTimeout(() => {
        setSelectedPlace(null);
      }, 500);
      setCloseTimeout(timeout);
    };

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const renderSlider = (images) => {
      if (images && Array.isArray(images) && images.length > 1) {
        // Si hay más de una imagen, renderiza el Slider
        return (
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Slide ${index}`}
                  style={{
                    maxWidth: "100%", // La imagen no excederá el ancho del contenedor
                    maxHeight: "40vh", // Establece la altura máxima
                    width: "auto", // La imagen mantiene su proporción
                    height: "auto", // La imagen mantiene su proporción
                    objectFit: "cover", // Cubre el espacio disponible sin distorsionar
                    borderRadius: 8, // Bordes redondeados opcionales
                  }}
                />
              </div>
            ))}
          </Slider>
        );
      } else if (images && Array.isArray(images) && images.length === 1) {
        // Si hay una sola imagen, renderiza la imagen sola
        return (
          <div>
            <img
              src={images[0]}
              alt="Single Image"
              style={{
                maxWidth: "100%",
                maxHeight: "40vh",
                width: "auto",
                height: "auto",
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
          </div>
        );
      } else {
        // Si no hay imágenes, renderiza un mensaje de error o una imagen por defecto
        return (
          <div
            style={{
              width: "auto",
              height: "auto",
              border: "1px solid grey",
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "grey", fontSize: 15 }}>Imagen no provista</h1>
          </div>
        );
      }
    };

    useEffect(() => {
      return () => {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
        }
      };
    }, [closeTimeout]);

    return (
      <>
        {pois.map((poi) => (
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            onMouseOver={() => handleMouseOver(poi)}
            onMouseOut={handleMouseOut}
            onClick={() => handleClick(poi)}
          >
            <Pin
              background={"#151414"}
              glyphColor={"#EC5306"}
              borderColor={"#EC5306"}
            />
          </AdvancedMarker>
        ))}
        {selectedPlace && (
          <InfoWindow
            position={selectedPlace.location}
            onCloseClick={() => setSelectedPlace(null)}
            onMouseOver={handleInfoWindowMouseOver}
            onMouseOut={handleInfoWindowMouseOut}
            style={{ width: "100%", overflowX: "hidden" }}
          >
            <div style={{ width: "100%", color: "black" }}>
              {renderSlider(selectedPlace.images)}
              <h2
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 20,
                }}
              >
                {selectedPlace.nombre}
              </h2>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Descripción:
                </strong>{" "}
                {selectedPlace.comentarios}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Rating:
                </strong>
                {selectedPlace.rate}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Cantidad de aros:
                </strong>
                {selectedPlace.cantAros}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Abierta siempre:
                </strong>{" "}
                {selectedPlace.abiertaSiempre ? "Sí" : "No"}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Gente activa:
                </strong>{" "}
                {selectedPlace.genteActiva}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Tiene cancha completa?
                </strong>{" "}
                {selectedPlace.canchaCompleta ? "Sí" : "No"}
              </p>
              <p
                style={
                  selectedPlace.comunidadData
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Instagram/Wpp: </strong>
                {selectedPlace.comunidadData}
              </p>
              <p
                style={
                  selectedPlace.extraInfo
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Informacion Extra: </strong>
                {selectedPlace.extraInfo}
              </p>
              <p
                style={
                  selectedPlace.cargadaPor
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Cargada por: </strong>
                {selectedPlace.cargadaPor}
              </p>
              <a
                style={
                  selectedPlace.google_photos_url
                    ? {
                        display: "block",
                        textDecoration: "none",
                        color: "#EC5306",
                      }
                    : { display: "none" }
                }
                href={selectedPlace.google_photos_url}
                target="_blank"
              >
                Ver mas fotos
              </a>
            </div>
          </InfoWindow>
        )}
      </>
    );
  };

  return (
    <div style={{ backgroundColor: "black", height: "1000px", marginTop: -50 }}>
      <Navbar />
      <Box
        sx={{
          width: "90%",
          maxWidth: "1000px",
          margin: "100px auto",
          padding: "20px",
          backgroundColor: "#2e2e2e", // Fondo gris oscuro
          borderRadius: "10px", // Bordes redondeados
          color: "#ffffff",
          textAlign: "center",
          boxShadow: "0px 4px 10px rgba(0,0,0,0.3)",
        }}
      >
        {evento ? (
          <div
            style={{
              textAlign: "left",
              justifyContent: "letf",
            }}
          >
            {evento.imageURL && (
              <img
                src={evento.imageURL}
                alt="Imagen del evento"
                onClick={() => setOpenModal(true)}
                style={{
                  width: "100%",
                  maxHeight: "300px",
                  objectFit: "cover",
                  borderRadius: "10px",
                  cursor: "pointer",
                }}
              />
            )}
            <Typography
              variant="h4"
              sx={{ color: "#ff9800", marginBottom: "10px", fontWeight: 700 }}
            >
              {evento.title}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              📅{" "}
              <span style={{ color: "#b4b2b2", fontWeight: 600 }}>Fecha: </span>
              {evento.start}
            </Typography>
            <Typography variant="body1" style={{ fontWeight: 600 }}>
              ⏰
              <span style={{ color: "#b4b2b2", fontWeight: 600 }}>
                {" "}
                Hora de inicio:{" "}
              </span>{" "}
              {evento.horaInicio}
            </Typography>

            {evento.contactoOrganizadores && (
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                📞{" "}
                <span style={{ color: "#b4b2b2", fontWeight: 600 }}>
                  Contacto:
                </span>
                {evento.contactoOrganizadores}
              </Typography>
            )}
            {evento.formato && (
              <Typography variant="body1" style={{ fontWeight: 600 }}>
                🏀 <span style={{ color: "#b4b2b2" }}>Formato: </span>{" "}
                {evento.formato}
              </Typography>
            )}
            {evento.urlInscripcion && (
              <Typography variant="body1" style={{ marginTop: 20 }}>
                🔗{" "}
                <a
                  href={evento.urlInscripcion}
                  target="_blank"
                  rel="noopener noreferrer"
                  style={{ color: "#ff9800", fontWeight: 700 }}
                >
                  Inscribirse al evento
                </a>
              </Typography>
            )}
            <Modal
              open={openModal}
              onClose={() => setOpenModal(false)}
              sx={{
                display: "flex",
                alignItems: "center",
                justifyContent: "center",
              }}
            >
              <img
                src={evento?.imageURL}
                alt="Imagen del evento en grande"
                style={{
                  width: "90%",
                  maxHeight: "90vh",
                  objectFit: "contain",
                  backgroundColor: "black",
                  borderRadius: "10px",
                }}
              />
            </Modal>
            <div
              style={{
                width: "100%",
                height: "50vh",
                overflow: "hidden",
                borderRadius: 20,
              }}
            >
              <Typography
                variant="h6"
                sx={{
                  color: "#ffffff",
                  marginBottom: "10px",
                  marginTop: "30px",
                  fontWeight: 700,
                }}
              >
                Ubicación del evento
              </Typography>
              <APIProvider
                apiKey={GOOGLE_MAPS_API_KEY}
                onLoad={() => console.log("Maps API has loaded.")}
              >
                <Map
                  defaultZoom={13}
                  defaultCenter={{
                    lat: evento.location.location.lat || -34.61315,
                    lng: evento.location.location.lng || -58.37723,
                  }}
                  mapId={"8a45de67aeece53b"}
                  onCameraChanged={(ev) =>
                    console.log(
                      "camera changed:",
                      ev.detail.center,
                      "zoom:",
                      ev.detail.zoom
                    )
                  }
                >
                  <PoiMarkers pois={locations} />
                </Map>
              </APIProvider>
            </div>
          </div>
        ) : (
          <Typography variant="h6">Cargando evento...</Typography>
        )}
      </Box>
    </div>
  );
};

export default EventoDetalle;
