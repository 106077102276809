import React from "react";

const SeparadorLanding = ({ imageUrl }) => {
  return (
    <div
      className="relative w-full h-[300px] bg-cover bg-center flex items-center justify-center"
      style={{
        backgroundImage: `url(${imageUrl})`,
      }}
    >
      {/* <div className="absolute inset-0 bg-black bg-opacity-50"></div>*/}
      <div className="absolute inset-0 bg-black bg-opacity-50"></div>
      <h2
        style={{
          fontWeight: 700,
          fontSize: 55,
          textTransform: "uppercase",
          color: "#dbd8d8",
          lineHeight: 1,
        }}
        className="relative text-4xl text-center px-4"
      >
        La aplicación del <span style={{ color: "#EC5306" }}>street</span>{" "}
        basket
      </h2>
    </div>
  );
};

export default SeparadorLanding;
