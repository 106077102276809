import React, { useState, useEffect, useContext } from "react";
import FullCalendar from "@fullcalendar/react";
import dayGridPlugin from "@fullcalendar/daygrid";
import interactionPlugin from "@fullcalendar/interaction";
import { createTheme, ThemeProvider } from "@mui/material/styles";
import {
  Dialog,
  DialogTitle,
  DialogContent,
  List,
  ListItem,
  ListItemText,
  Button,
} from "@mui/material";
import "./calendarStyles.css"; // Archivo de estilos personalizados
import NuevoEvento from "./nuevoEvento.js";
import esLocale from "@fullcalendar/core/locales/es";
import { styled } from "@mui/material/styles";
import { getEvents } from "../utils/firebase.utils";
import { useNavigate } from "react-router-dom";
import { UserProvider, UserContext } from "../contexts/userContext";
import SignIn from "./sign-in";
import dayjs from "dayjs";
import isSameOrAfter from "dayjs/plugin/isSameOrAfter";
dayjs.extend(isSameOrAfter);

const darkTheme = createTheme({
  palette: {
    mode: "dark",
    primary: { main: "#EC5306" },
    background: { default: "#121212", paper: "#1e1e1e" },
    text: { primary: "#ffffff", secondary: "#bbbbbb" },
  },
});

const ColorButtonOutlained = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#EC5306"),
  borderColor: "#EC5306",
  color: "#EC5306",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 50,
  width: "90%",
  marginTop: 60,
  marginBottom: 150,
}));

const Eventos = () => {
  const [selectedDate, setSelectedDate] = useState(null);
  const [open, setOpen] = useState(false);
  const [viewNewEvent, setViewNewEvent] = useState(false);
  const [events, setEvents] = useState({});
  const navigate = useNavigate();
  const { currentUser } = useContext(UserContext);

  useEffect(() => {
    const fetchEvents = async () => {
      const data = await getEvents();
      if (data) {
        // Transform the object to an array
        const eventsArray = Object.keys(data).map((key) => ({
          key: key,
          ...data[key],
        }));
        setEvents(eventsArray);
      }
    };

    fetchEvents();
    console.log("events: " + JSON.stringify(events));
  }, []);
  /*
  const events = [
    { title: "Partido en Plaza San Martín", start: "2025-02-20" },
    { title: "Torneo 3x3 en Parque Centenario", start: "2025-02-22" },
    { title: "Pick-up en Barrancas de Belgrano", start: "2025-02-22" },
  ];
  */
  /*
  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    const filteredEvents = events.filter(
      (event) => event.start === clickedDate
    );

    setSelectedDate({ date: clickedDate, events: filteredEvents });
    setViewNewEvent(false);
    setOpen(true);
  };
*/
  const handleDateClick = (info) => {
    const clickedDate = info.dateStr;
    openEventPopup(clickedDate);
  };

  const handleEventClick = (info) => {
    const eventDate = info.event.startStr.split("T")[0]; // Asegura que solo tomamos la fecha
    openEventPopup(eventDate);
  };

  const handleDateClickWrapper = (info) => {
    handleDateClick(info);
  };
  const openEventPopup = (date) => {
    const filteredEvents = events.filter((event) => event.start === date);

    setSelectedDate({ date: date, events: filteredEvents });
    setViewNewEvent(false);
    setOpen(true);
  };

  /*
  const handleEventClick = (info) => {
    handleDateClick(info.event);
  };
*/
  const handleClose = () => {
    setOpen(false);
    setViewNewEvent(false);
  };

  const isFutureOrToday = (dateStr) => {
    const today = dayjs().format("YYYY-MM-DD"); // Fecha actual en formato YYYY-MM-DD
    return dayjs(dateStr).isSameOrAfter(today);
  };

  return (
    <ThemeProvider theme={darkTheme}>
      {!currentUser && (
        <Dialog open={true} disableEscapeKeyDown>
          <DialogTitle
            style={{
              backgroundColor: "#1e1e1e",
              color: "#ffffff",
              textAlign: "center",
            }}
          >
            Inicia sesion para ver todas las funciones de QNPDP.
          </DialogTitle>
          <DialogContent
            style={{
              backgroundColor: "#1e1e1e",
              textAlign: "center",
              paddingBottom: "20px",
            }}
          >
            <SignIn buttonFormat={"primary"}></SignIn>
          </DialogContent>
        </Dialog>
      )}
      <div style={{ maxWidth: "900px", margin: "auto", padding: "20px" }}>
        <FullCalendar
          plugins={[dayGridPlugin, interactionPlugin]}
          initialView="dayGridMonth"
          locale={esLocale}
          events={events}
          dateClick={handleDateClickWrapper}
          eventClick={handleEventClick}
          height="auto"
          eventColor="#EC5306" // Color naranja para los eventos
        />

        {/* Modal de eventos */}
        <Dialog open={open} onClose={handleClose}>
          <DialogTitle style={{ backgroundColor: "#1e1e1e", color: "#EC5306" }}>
            {viewNewEvent
              ? "Crear Evento"
              : `Eventos del ${selectedDate?.date}`}
          </DialogTitle>
          <DialogContent style={{ backgroundColor: "#1e1e1e", color: "white" }}>
            {viewNewEvent ? (
              <NuevoEvento
                onCancelar={handleClose}
                eventDate={selectedDate?.date}
              />
            ) : (
              <>
                {selectedDate?.events.length ? (
                  <div
                    style={{
                      width: "100%",
                      textAlign: "center",
                      marginBottom: 30,
                    }}
                  >
                    {selectedDate.events.map((event, index) => (
                      <div
                        style={{
                          color: "#f6f6f6",
                          width: "100%",
                          marginTop: 10,
                          marginBottom: 10,
                          padding: 20,
                          fontWeight: 700,
                          display: "block",
                          textAlign: "center",
                          padding: "10px 0",
                          textDecoration: "none",
                          backgroundColor: "#606060",
                          borderRadius: 5,
                          cursor: "pointer",
                        }}
                        key={index}
                        onClick={() => navigate(`/app/eventos/${event.id}`)} // Redirigir con el ID del evento
                      >
                        {event.title}
                      </div>
                    ))}
                  </div>
                ) : (
                  <p style={{ color: "#ffffff" }}>
                    No hay eventos programados para este día.
                  </p>
                )}
                {selectedDate?.date && isFutureOrToday(selectedDate.date) && (
                  <Button
                    variant="contained"
                    color="primary"
                    fullWidth
                    onClick={() => setViewNewEvent(true)}
                    style={{ marginTop: "10px" }}
                  >
                    Crear Evento
                  </Button>
                )}
              </>
            )}

            <Button
              color="primary"
              fullWidth
              onClick={handleClose}
              style={{ marginTop: "10px", borderColor: "red" }}
            >
              Cerrar
            </Button>
          </DialogContent>
        </Dialog>
      </div>
    </ThemeProvider>
  );
};

export default Eventos;
