import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Button,
  Drawer,
  List,
  ListItem,
  ListItemText,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";
import { Link } from "react-router-dom"; // Para navegación en SPA
import logo from "../images/logo.png";
import { useNavigate } from "react-router-dom";

const Navbar = () => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("md")); // Detecta si es mobile
  const navigate = useNavigate(); // Hook para navegar

  const handleLogoClick = () => {
    navigate("/app"); // Redirige a la página principal
    window.parent.location = window.parent.location.href;
  };

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  return (
    <>
      {/* Navbar fijo */}
      <AppBar
        position="fixed"
        sx={{ backgroundColor: "black", borderBottom: "2px solid white" }}
      >
        <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
          {/* Logo */}
          <img
            src={logo}
            alt="logo"
            className="h-10 w-10"
            onClick={handleLogoClick}
          />

          {/* Modo Desktop */}
          {!isMobile && (
            <div style={{ display: "flex", gap: "20px" }}>
              <Button
                color="inherit"
                component={Link}
                to="/app?to=calendario-eventos"
              >
                VOLVER A EVENTOS
              </Button>
              <Button
                component={Link}
                to="/app"
                variant="outlined"
                sx={{ borderColor: "#EC5306", color: "#EC5306" }}
              >
                PROBAR LA APP
              </Button>
            </div>
          )}

          {/* Modo Mobile */}
          {isMobile && (
            <div
              style={{ display: "flex", alignItems: "center", width: "100%" }}
            >
              <IconButton
                color="inherit"
                onClick={handleDrawerToggle}
                sx={{ marginLeft: "auto" }}
              >
                <MenuIcon sx={{ color: "#EC5306" }} />
              </IconButton>
            </div>
          )}
        </Toolbar>
      </AppBar>

      {/* Drawer para menú mobile */}
      <Drawer anchor="right" open={mobileOpen} onClose={handleDrawerToggle}>
        <div
          style={{
            display: "flex",
            backgroundColor: "#1e1e1e",
            height: "100%",
            flexDirection: "column",
            paddingLeft: 20,
            paddingRight: 20,
          }}
        >
          <Button
            color="inherit"
            component={Link}
            to="/app"
            onClick={handleDrawerToggle}
            style={{ color: "white", padding: 30 }}
          >
            VOLVER A EVENTOS
          </Button>
          <Button
            component={Link}
            to="/app"
            variant="outlined"
            sx={{ borderColor: "#EC5306", color: "#EC5306" }}
          >
            PROBAR LA APP
          </Button>
        </div>
      </Drawer>
    </>
  );
};

export default Navbar;
