import * as React from "react";
import { useState, useEffect, useContext, useCallback, useMemo } from "react";
import { styled } from "@mui/material/styles";
import MenuItem from "@mui/material/MenuItem";
import Select from "@mui/material/Select";
import InputLabel from "@mui/material/InputLabel";
import FormControl from "@mui/material/FormControl";
import FormHelperText from "@mui/material/FormControl";
import Box from "@mui/material/Box";
import { UserContext } from ".././contexts/userContext.js";
import TextField from "@mui/material/TextField";
import Checkbox from "@mui/material/Checkbox";
import FormGroup from "@mui/material/FormGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import { getDatabase, ref as databaseRef, set } from "firebase/database";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { TimePicker } from "@mui/x-date-pickers";
import { v4 as uuidv4 } from "uuid"; // para generar un ID único
import {
  getStorage,
  ref as storageRef,
  uploadBytes,
  getDownloadURL,
} from "firebase/storage";
import {
  Typography,
  IconButton,
  Accordion,
  AccordionSummary,
  AccordionDetails,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  APIProvider,
  Map,
  AdvancedMarker,
  MapCameraChangedEvent,
  Pin,
  Marker,
  InfoWindow,
} from "@vis.gl/react-google-maps";
import Button from "@mui/material/Button";
import Slider from "react-slick";
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
} from "@mui/material";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { getLocations } from "../utils/firebase.utils";
import { FaStar } from "react-icons/fa";
import { GiBasketballBasket } from "react-icons/gi";
import { Padding } from "@mui/icons-material";
import { db } from "../utils/firebase.utils";
import { onValue, ref, update } from "firebase/database";
import SuccessModal from "./successModal.js"; // Asegúrate de importar el modal
import { useNavigate } from "react-router-dom";
// Personalizar el componente Select
const CustomSelect = styled(Select)(({ theme }) => ({
  "& .MuiSelect-select": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiSelect-select": {
    backgroundColor: "#EC5306",
  },
}));

const CustomInputLabel = styled(InputLabel)(({ theme }) => ({
  "&.Mui-focused": {
    color: "#EC5306",
  },
}));

const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  "& .MuiSvgIcon-root": {
    fill: "gray",
    fontSize: 28,
  },
  "&.Mui-checked .MuiSvgIcon-root": {
    fill: "#EC5306",
  },
  "&.Mui-checked": {
    color: "#EC5306",
  },
}));

const CustomTextField = styled(TextField)({
  "& .MuiInputBase-input": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
    borderRadius: 5,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
    borderRadius: 5,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiInputBase-input": {
    backgroundColor: "#C7C7C7",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306", // This ensures the orange border on focus
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#EC5306", // Orange color for the floating label
  },
});

const CustomDateTimePicker = styled(TimePicker)({
  "& .MuiInputBase-input": {
    backgroundColor: "gray",
    "&:hover": {
      backgroundColor: "#C7C7C7",
    },
    borderRadius: 5,
  },
  "& .MuiOutlinedInput-notchedOutline": {
    borderColor: "gray",
    borderRadius: 5,
  },
  "&:hover .MuiOutlinedInput-notchedOutline": {
    borderColor: "lightgray",
  },
  "&.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306",
  },
  "& .Mui-focused .MuiInputBase-input": {
    backgroundColor: "#C7C7C7",
  },
  "& .MuiOutlinedInput-root.Mui-focused .MuiOutlinedInput-notchedOutline": {
    borderColor: "#EC5306", // This ensures the orange border on focus
  },
  "& .MuiInputLabel-root.Mui-focused": {
    color: "#EC5306", // Orange color for the floating label
  },
});

const ColorButton = styled(Button)(({ theme }) => ({
  color: theme.palette.getContrastText("#EC5306"),
  backgroundColor: "#EC5306",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 50,
  width: "90%",
  marginTop: 60,
}));

const OrangeFormHelperText = styled(FormHelperText)(({ theme }) => ({
  color: "#EC5306", // O usa un color específico como '#FFA500' (naranja)
}));

export default function NuevoEvento({ eventDate }) {
  const [cantJugadores, setCantJugadores] = React.useState("");
  const [nivExp, setNivExp] = React.useState("");
  const [cuandoJugas, setCuandoJugas] = React.useState("");
  const [menuOpen, setMenuOpen] = React.useState(true);
  const [error, setError] = useState(null);
  const [locations, setLocations] = useState([]);
  const [filteredLocations, setFilteredLocations] = useState(locations);
  const [errors, setErrors] = useState({});
  const [imageFile, setImageFile] = useState(null);
  const [uploading, setUploading] = useState(false);

  const [gralSelectedPlace, setGralSelectedPlace] = useState(null);
  const { currentUser } = useContext(UserContext);
  const [formData, setFormData] = useState({
    title: "",
    urlInscripcion: "",
    contactoOrganizadores: "",
    formato: "",
    mixtoObligatorio: false,
  });

  // Estados para la hora de inicio y fin
  const [horaInicio, setHoraInicio] = useState("");
  const [horaFin, setHoraFin] = useState("");

  const [open, setOpen] = useState(false);
  const GOOGLE_MAPS_API_KEY = process.env.REACT_APP_GOOGLE_MAPS_API_KEY;

  const handleButtonClick = async () => {
    await handleCreateRecord();
  };
  const navigate = useNavigate();

  const handleRedirect = () => {
    alert("Evento agregado exitosamente");
    window.location.href = "/app?to=calendario-eventos";
    navigate("/app?to=calendario-eventos");
  };

  const handleClose = () => {
    setOpen(false);

    // Redirigir a la primera tab
    //switchToFirstTab();
  };

  const handleChange = useCallback((e) => {
    const { name, value, type, checked } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: type === "checkbox" ? checked : value,
    }));
  }, []);

  useEffect(() => {
    const fetchLocations = async () => {
      const data = await getLocations();
      if (data) {
        // Transform the object to an array
        const locationsArray = Object.keys(data).map((key) => ({
          key: key,
          ...data[key],
        }));
        setLocations(locationsArray);
        setFilteredLocations(locationsArray); // Inicialmente sin filtros
      }
    };

    fetchLocations();
  }, []);

  const handleImageChange = (e) => {
    if (e.target.files[0]) {
      setImageFile(e.target.files[0]); // Guarda la imagen seleccionada
    }
  };

  // Función para subir imagen a Firebase Storage
  const uploadImage = async (eventId) => {
    if (!imageFile) return null;

    const storage = getStorage();
    const imageRef = storageRef(storage, `events/${eventId}/${imageFile.name}`);

    try {
      await uploadBytes(imageRef, imageFile);
      const downloadURL = await getDownloadURL(imageRef);
      return downloadURL;
    } catch (error) {
      console.error("Error subiendo la imagen:", error);
      return null;
    }
  };

  const handleCreateRecord = async () => {
    if (currentUser && handleValidation()) {
      const db = getDatabase();
      const storage = getStorage();
      const eventId = uuidv4(); // Generar ID único para la locación
      const imageURL = await uploadImage(eventId); // Subir imagen y obtener URL

      const eventUpload = {
        ...formData,
        id: eventId,
        location: gralSelectedPlace,
        start: eventDate,
        horaInicio: horaInicio.format("HH:mm"),
        imageURL: imageURL || "",
        cargadaPor: currentUser.email,
      };
      try {
        // Inicializar Firebase

        //console.log("locationUpload: " + JSON.stringify(locationUpload));
        const eventRef = databaseRef(db, `events/${eventId}`);
        await set(eventRef, eventUpload);

        handleRedirect();
      } catch (error) {
        console.error("Error al agregar evento:", error);
        alert(
          "Ocurrió un error al intentar agregar el evento. Por favor, intente nuevamente."
        );
      } finally {
        setUploading(false);
      }
    }
  };

  const handleValidation = () => {
    let formErrors = {};
    let isValid = true;

    if (!gralSelectedPlace) {
      formErrors.notSelectedPlace = "Selecciona la cancha donde sera el evento";
      isValid = false;
    }

    if (
      !formData.title ||
      !formData.urlInscripcion ||
      !formData.contactoOrganizadores ||
      !formData.formato ||
      !horaInicio
    ) {
      setError("Por favor, complete todos los campos.");
      isValid = false;
    }

    setErrors(formErrors);
    return isValid;
  };

  const PoiMarkers = ({ pois }) => {
    const [selectedPlace, setSelectedPlace] = useState(null);
    const [isMouseOverInfoWindow, setIsMouseOverInfoWindow] = useState(false);
    const [closeTimeout, setCloseTimeout] = useState(null);

    const handleMouseOver = (poi) => {
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
      setSelectedPlace(poi);
    };

    const handleMouseOut = () => {
      if (!isMouseOverInfoWindow) {
        const timeout = setTimeout(() => {
          setSelectedPlace(null);
        }, 800);
        setCloseTimeout(timeout);
      }
    };

    const handleClick = (poi) => {
      setSelectedPlace(poi);
      setGralSelectedPlace(poi);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOver = () => {
      setIsMouseOverInfoWindow(true);
      if (closeTimeout) {
        clearTimeout(closeTimeout);
        setCloseTimeout(null);
      }
    };

    const handleInfoWindowMouseOut = () => {
      setIsMouseOverInfoWindow(false);
      const timeout = setTimeout(() => {
        setSelectedPlace(null);
      }, 500);
      setCloseTimeout(timeout);
    };

    const sliderSettings = {
      dots: true,
      infinite: true,
      speed: 500,
      slidesToShow: 1,
      slidesToScroll: 1,
    };

    const renderStars = (rate) => {
      const stars = [];
      for (let i = 0; i < 5; i++) {
        if (rate >= 1) {
          stars.push(<FaStar key={i} color="#EC5306" />);
          rate -= 1;
        } else if (rate > 0) {
          stars.push(
            <FaStar key={i} color="#EC5306" style={{ opacity: rate }} />
          );
          rate = 0;
        } else {
          stars.push(<FaStar key={i} color="#CCCCCC" />);
        }
      }
      return <div style={{ display: "flex" }}>{stars}</div>;
    };

    const renderBasketballHoops = (cantAros) => {
      const hoops = [];
      if (parseInt(cantAros) > 0) {
        for (let i = 0; i < parseInt(cantAros); i++) {
          hoops.push(<GiBasketballBasket key={i} color="#EC5306" size={25} />);
        }
      } else {
        hoops.push(<Box>Dato no provisto</Box>);
      }

      return <div style={{ display: "flex" }}>{hoops}</div>;
    };

    const renderSlider = (images) => {
      if (images && Array.isArray(images) && images.length > 1) {
        // Si hay más de una imagen, renderiza el Slider
        return (
          <Slider {...sliderSettings}>
            {images.map((image, index) => (
              <div key={index}>
                <img
                  src={image}
                  alt={`Slide ${index}`}
                  style={{
                    maxWidth: "100%", // La imagen no excederá el ancho del contenedor
                    maxHeight: "40vh", // Establece la altura máxima
                    width: "auto", // La imagen mantiene su proporción
                    height: "auto", // La imagen mantiene su proporción
                    objectFit: "cover", // Cubre el espacio disponible sin distorsionar
                    borderRadius: 8, // Bordes redondeados opcionales
                  }}
                />
              </div>
            ))}
          </Slider>
        );
      } else if (images && Array.isArray(images) && images.length === 1) {
        // Si hay una sola imagen, renderiza la imagen sola
        return (
          <div>
            <img
              src={images[0]}
              alt="Single Image"
              style={{
                maxWidth: "100%",
                maxHeight: "40vh",
                width: "auto",
                height: "auto",
                objectFit: "cover",
                borderRadius: 8,
              }}
            />
          </div>
        );
      } else {
        // Si no hay imágenes, renderiza un mensaje de error o una imagen por defecto
        return (
          <div
            style={{
              width: "auto",
              height: "auto",
              border: "1px solid grey",
              borderRadius: 5,
              justifyContent: "center",
              alignItems: "center",
              textAlign: "center",
            }}
          >
            <h1 style={{ color: "grey", fontSize: 15 }}>Imagen no provista</h1>
          </div>
        );
      }
    };

    useEffect(() => {
      return () => {
        if (closeTimeout) {
          clearTimeout(closeTimeout);
        }
      };
    }, [closeTimeout]);

    return (
      <>
        {pois.map((poi) => (
          <AdvancedMarker
            key={poi.key}
            position={poi.location}
            onMouseOver={() => handleMouseOver(poi)}
            onMouseOut={handleMouseOut}
            onClick={() => handleClick(poi)}
          >
            <Pin
              background={"#151414"}
              glyphColor={"#EC5306"}
              borderColor={"#EC5306"}
            />
          </AdvancedMarker>
        ))}
        {selectedPlace && (
          <InfoWindow
            position={selectedPlace.location}
            onCloseClick={() => setSelectedPlace(null)}
            onMouseOver={handleInfoWindowMouseOver}
            onMouseOut={handleInfoWindowMouseOut}
            style={{ width: "100%", overflowX: "hidden" }}
          >
            <div style={{ width: "100%" }}>
              {renderSlider(selectedPlace.images)}
              <h2
                style={{
                  fontWeight: 700,
                  fontSize: 20,
                  marginTop: 30,
                  marginBottom: 20,
                }}
              >
                {selectedPlace.nombre}
              </h2>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Descripción:
                </strong>{" "}
                {selectedPlace.comentarios}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Rating:
                </strong>{" "}
                {renderStars(selectedPlace.rate)}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Cantidad de aros:
                </strong>{" "}
                {renderBasketballHoops(selectedPlace.cantAros)}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Abierta siempre:
                </strong>{" "}
                {selectedPlace.abiertaSiempre ? "Sí" : "No"}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Gente activa:
                </strong>{" "}
                {selectedPlace.genteActiva}
              </p>
              <p>
                <strong style={{ fontWeight: 600, fontSize: 14 }}>
                  Tiene cancha completa?
                </strong>{" "}
                {selectedPlace.canchaCompleta ? "Sí" : "No"}
              </p>
              <p
                style={
                  selectedPlace.comunidadData
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Instagram/Wpp: </strong>
                {selectedPlace.comunidadData}
              </p>
              <p
                style={
                  selectedPlace.extraInfo
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Informacion Extra: </strong>
                {selectedPlace.extraInfo}
              </p>
              <p
                style={
                  selectedPlace.cargadaPor
                    ? { display: "block" }
                    : { display: "none" }
                }
              >
                <strong>Cargada por: </strong>
                {selectedPlace.cargadaPor}
              </p>
              <a
                style={
                  selectedPlace.google_photos_url
                    ? {
                        display: "block",
                        textDecoration: "none",
                        color: "#EC5306",
                      }
                    : { display: "none" }
                }
                href={selectedPlace.google_photos_url}
                target="_blank"
              >
                Ver mas fotos
              </a>
            </div>
          </InfoWindow>
        )}
      </>
    );
  };

  const MapComponent = useMemo(
    () => (
      <div
        style={{
          width: "100%",
          height: "50vh",
          overflow: "hidden",
          borderRadius: 20,
        }}
      >
        <APIProvider
          apiKey={GOOGLE_MAPS_API_KEY}
          onLoad={() => console.log("Maps API has loaded.")}
        >
          <Map
            defaultZoom={13}
            defaultCenter={{ lat: -34.61315, lng: -58.37723 }}
            mapId={"8a45de67aeece53b"}
            onCameraChanged={(ev) =>
              console.log(
                "camera changed:",
                ev.detail.center,
                "zoom:",
                ev.detail.zoom
              )
            }
          >
            <PoiMarkers pois={locations} />
          </Map>
        </APIProvider>
      </div>
    ),
    [locations]
  );

  return (
    <Box>
      <div
        style={{
          width: "96%",
          marginLeft: "2%",
        }}
      >
        <p
          style={{
            color: "#D9D8D8",
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          SELECCIONA LA CANCHA DONDE SERA EL EVENTO:{" "}
        </p>
        <p
          style={
            errors.notSelectedPlace
              ? { color: "red", fontWeight: "600" }
              : { display: "none" }
          }
        >
          Seleccione el lugar donde quiere jugar.{" "}
        </p>
        <div
          style={{
            width: "100%",
            height: "50vh",
            overflow: "hidden",
            borderRadius: 20,
          }}
        >
          {MapComponent}
        </div>
      </div>
      <Box
        sx={{
          display: "flex",
          flexDirection: "column",
          gap: 2,
          justifyContent: "center",
          textAlign: "center",
          alignContent: "center",
          alignItems: "center",
        }}
      >
        <p
          style={{
            color: "#D9D8D8",
            fontWeight: "600",
            marginTop: 40,
            marginBottom: 20,
          }}
        >
          INFORMACION DEL EVENTO:{" "}
        </p>
        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            width: "85%",
          }}
        >
          <CustomTextField
            label="Nombre del evento"
            name="title"
            value={formData.title}
            onChange={handleChange}
            required
          />
          <CustomTextField
            label="Link para inscripcion"
            name="urlInscripcion"
            value={formData.urlInscripcion}
            onChange={handleChange}
            required
          />
          <CustomTextField
            label="Contacto Organizadores"
            name="contactoOrganizadores"
            value={formData.contactoOrganizadores}
            onChange={handleChange}
            required
          />

          <FormControl fullWidth>
            <CustomInputLabel id="formato-select-label">
              Formato *
            </CustomInputLabel>
            <CustomSelect
              labelId="formato-select-label"
              id="formato-select"
              name={"formato"}
              value={formData.formato}
              label="formato"
              onChange={handleChange}
              required={true}
            >
              <MenuItem value={"1 vs 1"}>1 vs 1</MenuItem>
              <MenuItem value={"2 vs 2"}>2 vs 2</MenuItem>
              <MenuItem value={"3 vs 3"}>3 vs 3</MenuItem>
              <MenuItem value={"5 vs 5"}>5 vs 5</MenuItem>
            </CustomSelect>
          </FormControl>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDateTimePicker
              label="Hora de inicio *"
              required={true}
              onChange={(newTime) => setHoraInicio(newTime)}
              slotProps={{
                // Targets the `IconButton` component.
                openPickerButton: {
                  color: "error",
                },
              }}
            />
          </LocalizationProvider>

          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <CustomDateTimePicker
              required={true}
              label="Hora de fin *"
              onChange={(newTime) => setHoraFin(newTime)}
              slotProps={{
                // Targets the `IconButton` component.
                openPickerButton: {
                  color: "error",
                },
              }}
            />
          </LocalizationProvider>
        </Box>

        <Box
          sx={{
            display: "flex",
            flexDirection: "column",
            gap: 2,
            color: "white",
            "& .MuiFormControlLabel-label": {
              fontWeight: "bold",
            },
            width: "85%",
          }}
        >
          <FormControlLabel
            control={
              <CustomCheckbox
                checked={formData.mixtoObligatorio}
                onChange={handleChange}
                name="mixtoObligatorio"
              />
            }
            label="Mixto obligatorio?"
          />
        </Box>
        {/* Mostrar error si existe */}
        {error && <Box sx={{ color: "red" }}>{error}</Box>}

        {/* Mostrar archivos seleccionados */}

        {/*selectedFiles && (
          <Box sx={{ mt: 2 }}>
            <h3>Archivos seleccionados:</h3>
            <ul>
              {Array.from(selectedFiles).map((file, index) => (
                <li key={index}>{file.name}</li>
              ))}
            </ul>
          </Box>
        )*/}
      </Box>

      <div
        style={{
          width: "100%",
          justifyContent: "center",
          textAlign: "center",
        }}
      >
        {/* Input para subir imagen */}
        <input
          type="file"
          accept="image/*"
          onChange={handleImageChange}
          style={{ marginTop: "10px", color: "white" }}
        />
        {/* Botón de enviar */}
        <ColorButton
          variant="contained"
          color="primary"
          disabled={uploading}
          onClick={handleButtonClick}
        >
          Cargar Evento
        </ColorButton>
      </div>
    </Box>
  );
}
