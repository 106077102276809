import { useState, useContext } from "react";
import { UserContext } from ".././contexts/userContext.js";
import {
  signInWithGooglePopup,
  createUserProfileDocument,
} from "../utils/firebase.utils";
import Button from "@mui/material/Button";
import Box from "@mui/material/Box";
import { styled } from "@mui/material/styles";
import CircularProgress from "@mui/material/CircularProgress";
import Alert from "@mui/material/Alert";
import Logout from "./logout.js"; // Import the Logout component
import PersonIcon from "@mui/icons-material/Person";
import IconButton from "@mui/material/IconButton";

const ColorButton = styled(Button)(({ theme }) => ({
  color: "#EC5306",
  backgroundColor: "black",
  "&:hover": {
    backgroundColor: "#EC5306",
  },
  borderRadius: 10,
  height: 40,
  width: "100%",
  border: "1px solid",
  borderColor: "#EC5306",
}));

const SignIn = (props) => {
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState(null);
  const { currentUser } = useContext(UserContext);

  const { buttonFormat } = props;

  const logGoogleUser = async () => {
    try {
      setLoading(true);
      setError(null);

      const response = await signInWithGooglePopup();

      if (!response) {
        throw new Error("No se pudo completar la autenticación.");
      }

      const { user } = response;

      if (!user) {
        throw new Error("No se pudo obtener la información del usuario.");
      }

      // Guardar usuario en Firebase Realtime Database
      await createUserProfileDocument(user);

      console.log("Usuario autenticado:", user);
    } catch (error) {
      if (error.message.includes("auth/popup-closed-by-user")) {
        setError(
          "El popup de Google se cerró antes de completar el inicio de sesión."
        );
      } else {
        setError("Error en la autenticación: " + error.message);
      }
    } finally {
      setLoading(false);
    }
  };

  return (
    <Box
      sx={{
        display: "flex",
        justifyContent: "center",
        paddingTop: "20px",
        flexDirection: "column",
        alignItems: "center",
      }}
    >
      {error && (
        <Alert severity="error" sx={{ marginBottom: "10px" }}>
          {error}
        </Alert>
      )}
      {currentUser ? (
        <div style={{ color: "white", width: "100%" }}>
          <Logout />
        </div>
      ) : //Bienvenido, {currentUser.displayName}!{" "}
      buttonFormat == "person" ? (
        <IconButton
          //edge="end"
          color="#EC5306"
          aria-label="person"
          onClick={logGoogleUser}
          sx={{
            color: "#EC5306",
            marginTop: -3,
          }}
        >
          <PersonIcon color="#EC5306" />
        </IconButton>
      ) : buttonFormat == "landing" ? (
        <a
          onClick={logGoogleUser}
          className="border-2 border-grey text-white px-8 py-2 rounded-lg text-lg font-semibold hover:bg-white/10 transition-all w-full sm:w-auto"
          style={{ marginBottom: 15 }}
        >
          Registrate
        </a>
      ) : buttonFormat == "primary" ? (
        <Button
          variant="contained"
          color="primary"
          onClick={logGoogleUser}
          sx={{ marginTop: "10px", backgroundColor: "#EC5306" }}
        >
          Login con Google
        </Button>
      ) : (
        <ColorButton
          variant="contained"
          onClick={logGoogleUser}
          disabled={loading}
          style={{ fontSize: 12, fontWeight: 600 }}
        >
          {loading ? (
            <CircularProgress size={24} />
          ) : (
            "Iniciar sesión con Google"
          )}
        </ColorButton>
      )}
    </Box>
  );
};

export default SignIn;
